body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*:root {
  --primary-color: #343E4A; 
  --secondary-color: #47586B; 
  --background-color: #1D242C; 
  --text-color: #FFFFFF; 
  --chat-text-color: #ECECF1; 
  --sidebar-text-color: #D7D7D7; 
  --orange-text-color:#FF8748;
}
*/
/*body {
  background-color: #1D242C //var(--background-color);
  /*color: var(--text-color);
}*/
